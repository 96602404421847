interface ToggleProps {
	label: string
	onChange: () => void
	checked: boolean
	disabled?: boolean
}
export default function Toggle({
	label,
	onChange,
	checked,
	disabled = false,
}: ToggleProps) {
	return (
		<label className="relative inline-flex cursor-pointer items-center">
			<input
				type="checkbox"
				checked={checked}
				className="peer sr-only"
				role="switch"
				onChange={onChange}
				disabled={disabled}
			/>
			<div
				className={`peer h-6 w-11 rounded-full bg-gray-300 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-secondary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-secondary/50 dark:border-gray-600 dark:bg-gray-700 ${
					disabled ? 'cursor-not-allowed peer-checked:!bg-gray-200' : ''
				}`}
			></div>
			<span className="text-background-foreground ml-3 text-sm font-medium">
				{label}
			</span>
		</label>
	)
}
